
<template>
  <div class="page">
    <div style="text-align:center;display:flex;justify-content: space-evenly;
        align-items: center;margin: 10px 0;">
          <div style="font-size:1.2rem;font-weight: bolder;flex:1;width:100%;">数据总览</div>
          <div>
            筛选：<a-date-picker :open='yearShowOne' @openChange="openChangeOne" @panelChange="panelChangeOne" format='YYYY'
            @change="onChange" v-model="searchForm.year" size="small" placeholder="选择年份" mode="year" />
          </div>
        </div>
    <a-row :gutter="[16,16]">
      <a-col :span="24" >
        
        <a-card>
          <div id="container1"></div>
        </a-card>
      </a-col>

    </a-row>
    <a-row>
      <a-col :span="8">
        <p style="text-align:center;margin: 10px 0;font-weight: bolder;">
          政策咨询
        </p>
        <a-card>
          <div id="c1"></div>
        </a-card>

      </a-col>
      <a-col :span="8">
        <p style="text-align:center;margin: 10px 0;font-weight: bolder;">
          反映诉求
        </p>
        <a-card>
          <div id="c2"></div>
        </a-card>

      </a-col>
      <a-col :span="8">
        <p style="text-align:center;margin: 10px 0;font-weight: bolder;">
          举报干部
        </p>
        <a-card>
          <div id="c3"></div>
        </a-card>

      </a-col>
    </a-row>
  </div>
</template>
<script >
import { Chart } from '@antv/g2';


export default {
  data() {
    return {
      chartInstance: null,
      consultationInstance: null,
      appealInstance: null,
      reportDataInstance: null,

      chartData: [],
      ConsultationData: [],
      AppealData: [],
      ReportData: [],

      yearShowOne: false,
      searchForm: { year: null },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getStatistic().then(_ => {
        this.init()
      })
    })
  },
  methods: {
    async getStatistic() {
      this.chartData = await this.axios.post("admin/statistic", this.searchForm)
      this.ConsultationData = this.chartData.filter(item => {
        if (item.name == "咨询") {
          return item;
        }
      })
      this.AppealData = this.chartData.filter(item => {
        if (item.name == "诉求") {
          return item;
        }
      })
      this.ReportData = this.chartData.filter(item => {
        if (item.name == "举报") {
          return item;
        }
      })
    },
    init() {
      const chart = new Chart({
        container: 'container1',
        autoFit: true,
        height: 300,

      });
      const c1 = new Chart({
        container: 'c1',
        autoFit: true,
        height: 300,

      });
      const c2 = new Chart({
        container: 'c2',
        autoFit: true,
        height: 300,

      });
      const c3 = new Chart({
        container: 'c3',
        autoFit: true,
        height: 300,

      });
      this.chartInstance = chart
      this.consultationInstance = c1
      this.appealInstance = c2
      this.reportInstance = c3
      this.make(this.chartInstance, this.chartData, 'interval')
      this.make(this.consultationInstance, this.ConsultationData, 'line')
      this.make(this.appealInstance, this.AppealData, 'line')
      this.make(this.reportInstance, this.ReportData, 'line')
    },
    //装载数据并渲染
    make(chart, data, method) {
      chart.data(data);
      chart.scale({
        total: {
          nice: true,
          alias: '这里设置标题的别名'
        },
        month: {
          type: 'timeCat',
          alias: '月份',
          ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          mask: "YYYY-MM"
          // formatter:(value)=>{value}
        }
      });

      chart[method]()
        .position('month*total')
        .adjust([
          {
            type: "dodge"
          }
        ])
        .color('name').label("total");
      chart.interaction('active-region');
      chart.render();
    },
    onChange(date, dateString) {
      console.log("onChange", date, dateString);
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true
      }
    },
    // 得到年份选择器的值
    async panelChangeOne(value) {
      let aa = value.toString();
      this.searchForm.year = aa.substring(11, 15);

      this.yearShowOne = false;
      //销毁之前的表格


      this.chartInstance.clear();
      this.consultationInstance.clear();
      this.appealInstance.clear();
      this.reportInstance.clear();
      await this.getStatistic().then(_ => {
        this.make(this.chartInstance, this.chartData, 'interval')
        this.make(this.consultationInstance, this.ConsultationData, 'line')
        this.make(this.appealInstance, this.AppealData, 'line')
        this.make(this.reportInstance, this.ReportData, 'line')
      })
    }
  }


}
</script>
<style lang="scss" scoped>

</style>

